.form {
  --form-width: 25rem;

  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: 1fr 1fr;
  margin-left: auto;
  margin-right: auto;
  max-width: min(var(--width-container), var(--form-width));
  min-width: 0;
  position: relative;
  width: 100%;

  > *,
  > fieldset > * {
    grid-column: 1 / -1;
    place-content: flex-start;
  }

  & > .validation-message {
    margin-bottom: var(--gap);
  }

  h3 {
    margin: 0;
    padding-top: var(--gap);
  }

  & > p {
    margin: 0;
  }

  & > fieldset:not(.field) {
    appearance: none;
    border: 0;
    padding: 0;

    @supports (display: contents) {
      display: contents;
    }
    @supports (display: subgrid) {
      display: subgrid;
    }

    & > legend {
      appearance: none;
      color: var(--color-heading);
      font-family: var(--font-family-heading);
      font-size: var(--font-size-x-large);
      margin: var(--gap) 0 var(--gap-small);
    }
  }

  & > footer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: var(--gap-small) 0;

    & > .button[type='reset']:first-child,
    & > .button.secondary:first-child {
      margin-right: auto;
    }
  }
}
