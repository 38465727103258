.baseStyle {
  --acceptBorderColor: #00e676;
  --baseBgColor: #fafafa;
  --baseBorderColor: #eee;
  --baseColor: #bdbdbd;
  --focusedBorderColor: #2196f3;
  --rejectBorderColor: #ff1744;

  align-items: center;
  background-color: var(--baseBgColor);
  border-color: var(--baseBorderColor);
  border-radius: 2px;
  border-style: dashed;
  border-width: 2px;
  color: var(--baseColor);
  display: flex;
  flex: 1;
  flex-direction: column;
  outline: none;
  padding: 20px;
  transition: border 0.24s ease-in-out;
}

.focusedStyle {
  border-color: var(--focusedBorderColor);
}

.acceptStyle {
  border-color: var(--acceptBorderColor);
}

.rejectStyle {
  border-color: var(--rejectBorderColor);
}
