.file-input {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.downloading {
  filter: blur(0.5rem);
}

.buttons-wrapper {
  order: 2;
}
